import { isObjectResponse } from '@bas/value-objects';
import type { PlannedEmployee } from '../types';

export function isPlannedEmployee(object: unknown): object is PlannedEmployee {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'PlannedEmployee'
  );
}
