import { isWorkforceProvider, WorkforceProvider } from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type WorkforceProviderByWorkforceProviderIdRequestProps = {
  workforceProviderId: Uuid;
};

type Response = AxiosResponse<WorkforceProvider>;

export const WorkforceProviderByWorkforceProviderIdRequest = async ({
  workforceProviderId,
  ...params
}: WorkforceProviderByWorkforceProviderIdRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/hrm/workforce-providers/${workforceProviderId}`, {
    params,
  });

export const useWorkforceProviderByWorkforceProviderIdRequestQuery = (
  request: WorkforceProviderByWorkforceProviderIdRequestProps,
  options: QueryOptionsWithKey<Response, AxiosError, Response> = {}
): UseQueryResult<Response, AxiosError> =>
  useQuery<Response, AxiosError, Response>({
    ...options,
    queryFn: async () => WorkforceProviderByWorkforceProviderIdRequest(request),
    queryKey: ['workforce-providers', 'detail', request.workforceProviderId],
  });

export const WorkforceProviderByWorkforceProviderIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isWorkforceProvider(data)) {
      queryClient.invalidateQueries({
        queryKey: ['workforce-providers', 'detail', data.workforceProviderId],
      });
    }
  };
