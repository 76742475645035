import { isObjectResponse } from '@bas/value-objects';
import type { TemporaryEmployee } from '../types';

export function isTemporaryEmployee(
  object: unknown
): object is TemporaryEmployee {
  return (
    !!object &&
    isObjectResponse(object) &&
    object['@type'] === 'TemporaryEmployee'
  );
}
