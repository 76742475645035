import { isResourceRequest, ResourceRequest } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type ResourceRequestsRequestProps = Pagination & Filterable;

export const ResourceRequestsRequest = async ({
  ...params
}: ResourceRequestsRequestProps): Promise<
  AxiosResponse<Collection<ResourceRequest>>
> =>
  axios.get(`api/{tenantId}/hrm/resource-requests`, {
    params: { ...params },
  });

export const useResourceRequestsRequest = (
  request: ResourceRequestsRequestProps = { perPage: 9999 },
  options: QueryOptionsWithKey<
    AxiosResponse<Collection<ResourceRequest>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<ResourceRequest>>
  > = {}
): UseQueryResult<
  AxiosResponse<Collection<ResourceRequest>>,
  AxiosError<ErrorResponse>
> =>
  useQuery<
    AxiosResponse<Collection<ResourceRequest>>,
    AxiosError<ErrorResponse>,
    AxiosResponse<Collection<ResourceRequest>>
  >({
    ...options,
    queryFn: async () => ResourceRequestsRequest(request),
    queryKey: ['resource-requests', 'list', request],
  });

export const ResourceRequestsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isResourceRequest(data)) {
    queryClient.invalidateQueries({ queryKey: ['resource-requests', 'list'] });
  }
};
