/* eslint-disable no-param-reassign */
import { useAppStore, useAuthStore, useEmployeeStore, useTenantStore, useUserStore } from '@bas/shared/state';
import axios from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export function isFormData(object: unknown): object is FormData {
  return object instanceof FormData;
}

export const useAxiosInterceptors = (
  isReactNativeWeb = false
): {
  isComplete: boolean;
  hasSetEmployeeId: boolean;
  hasSetSessionId: boolean;
  hasSetTenantId: boolean;
} => {
  const { tenantId, timezone } = useTenantStore((state) => ({
    timezone: state.tenant?.timezone,
    tenantId: state.tenant?.tenantId,
  }));

  const roles = useUserStore((state) => state.user?.roles);

  const employeeId = useEmployeeStore((state) => state.employee?.employeeId);
  const { apiUrl, deviceId } = useAppStore((state) => ({
    deviceId: state.deviceId,
    apiUrl: state.apiUrl,
  }));
  const {
    token,
    getToken,
    getExpiresAt,
    isAuthenticated,
    getIsRefreshing,
    sessionId,
  } = useAuthStore((state) => ({
    token: state.token,
    getToken: state.getToken,
    getExpiresAt: state.getExpiresAt,
    isAuthenticated: state.isAuthenticated,
    getIsRefreshing: state.getIsRefreshing,
    sessionId: state.sessionId,
  }));

  const [isComplete, setIsComplete] = useState(false);
  const [hasSetEmployeeId, setHasSetEmployeeId] = useState(false);
  const [hasSetTenantId, setHasSetTenantId] = useState(false);
  const [hasSetSessionId, setHasSetSessionId] = useState(false);
  const [hasSetToken, setHasSetToken] = useState(false);

  useEffect(() => {
    setIsComplete(false);
    if (isReactNativeWeb && !hasSetTenantId) {
      return;
    }

    if (!isAuthenticated && !tenantId && !isReactNativeWeb) {
      setIsComplete(true);
    }

    if (
      (!hasSetEmployeeId && !(roles || []).includes('ROLE_SUPER_ADMIN')) ||
      !hasSetTenantId ||
      !hasSetSessionId ||
      !hasSetToken
    ) {
      return;
    }

    setIsComplete(true);
  }, [
    hasSetEmployeeId,
    hasSetSessionId,
    hasSetTenantId,
    hasSetToken,
    isAuthenticated,
    isReactNativeWeb,
    roles,
    tenantId,
  ]);

  useEffect(() => {
    const index = axios.interceptors.request.use((config) => {
      if (
        typeof config.data === 'object' &&
        !Array.isArray(config.data) &&
        !isFormData(config.data)
      ) {
        config.data.originalTimezone = timezone;
      }

      return config;
    });

    return () => {
      axios.interceptors.request.eject(index);
    };
  }, [timezone]);

  useEffect(() => {
    const index = axios.interceptors.request.use((config) => {
      if (
        typeof config.data === 'object' &&
        !Array.isArray(config.data) &&
        !isFormData(config.data) &&
        !config.data.employeeId
      ) {
        config.data.employeeId = employeeId;
      }

      return config;
    });

    setHasSetEmployeeId(!!employeeId);
    return () => {
      setHasSetEmployeeId(false);
      axios.interceptors.request.eject(index);
    };
  }, [employeeId]);

  useEffect(() => {
    const index = axios.interceptors.request.use((config) => {
      if (
        typeof config.data === 'object' &&
        !Array.isArray(config.data) &&
        !isFormData(config.data)
      ) {
        config.data.deviceId = deviceId;
      }

      if (config.headers) {
        config.headers['X-Device-Id'] = deviceId;
      }

      return config;
    });

    return () => {
      axios.interceptors.request.eject(index);
    };
  }, [deviceId]);

  useEffect(() => {
    const index = axios.interceptors.request.use((config) => {
      if (config.headers && sessionId) {
        config.headers['X-Session-Id'] = sessionId || '';
      }

      return config;
    });

    setHasSetSessionId(!!sessionId);
    return () => {
      setHasSetSessionId(false);
      axios.interceptors.request.eject(index);
    };
  }, [sessionId]);

  useEffect(() => {
    const index = axios.interceptors.request.use((config) => {
      if (config.baseURL && config.baseURL !== apiUrl && !window) {
        config.baseURL = apiUrl;
      }

      return config;
    });

    return () => {
      axios.interceptors.request.eject(index);
    };
  }, [apiUrl]);

  useEffect(() => {
    const index = axios.interceptors.request.use(
      (config) => {
        if (config.url && config.url.includes('{tenantId}') && tenantId) {
          config.url = config.url.replace('{tenantId}', tenantId);
        }

        if (config.url && config.url.includes('{tenantId}')) {
          // eslint-disable-next-line no-console
          console.error('TenantId is not set');
        }

        if (
          typeof config.data === 'object' &&
          !Array.isArray(config.data) &&
          !isFormData(config.data)
        ) {
          config.data.tenantId = tenantId;
        }

        return config;
      },
      (err) => Promise.reject(err)
    );

    setHasSetTenantId(!!tenantId);
    return () => {
      setHasSetTenantId(false);
      axios.interceptors.request.eject(index);
    };
  }, [tenantId]);

  useEffect(() => {
    const index = axios.interceptors.request.use(
      (config) => {
        const currentToken = getToken() || '';
        if (
          currentToken &&
          config.headers &&
          !config.url?.includes('/login') &&
          !config.url?.includes('/public/') &&
          !config.url?.includes('/auth/refresh') &&
          !config?.headers?.Authorization &&
          // eslint-disable-next-line dot-notation
          !config?.headers?.['skipAuthorization']
        ) {
          const expiresAt = getExpiresAt();
          if (getIsRefreshing()) {
            throw new Error(
              'Token is refreshing, we should wait for new token'
            );
          } else if (expiresAt.subtract(15, 'seconds').isBefore(dayjs())) {
            // eslint-disable-next-line no-console
            console.log(expiresAt.format('YYYY-MM-DD HH:mm:ss'));

            throw new Error('Token is expired, we should refresh token');
          }

          config.headers.Authorization = `Bearer ${currentToken}`;
        }

        return config;
      },
      (err) => Promise.reject(err)
    );

    setHasSetToken(!!token);

    return () => {
      setHasSetToken(false);
      axios.interceptors.request.eject(index);
    };
  }, [token, getToken, getExpiresAt, getIsRefreshing]);

  useEffect(() => {
    const index = axios.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject(error)
    );

    return () => {
      axios.interceptors.response.eject(index);
    };
  }, []);

  return {
    isComplete,
    hasSetEmployeeId,
    hasSetSessionId,
    hasSetTenantId,
  };
};
