import { isResourceRequest, ResourceRequest } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type ResourceRequestsByWorkforceProviderIdAndDateProps = Filterable &
  Pagination & {
    workforceProviderId: Uuid;
    date: Date;
  };

type Response = AxiosResponse<Collection<ResourceRequest>>;

export const ResourceRequestsByWorkforceProviderIdAndDate = async ({
  workforceProviderId,
  date,
  ...params
}: ResourceRequestsByWorkforceProviderIdAndDateProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/workforce-providers/${workforceProviderId}/resource-requests/${formatDate(
      date
    )}`,
    {
      params: {
        ...params,
      },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    }
  );

export const useResourceRequestsPerDayByWorkforceProviderIdAndDate = (
  {
    workforceProviderId,
    ...request
  }: ResourceRequestsByWorkforceProviderIdAndDateProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      ResourceRequestsByWorkforceProviderIdAndDate({
        ...request,
        workforceProviderId,
      }),
    queryKey: [
      'resource-request',
      'list',
      workforceProviderId,
      formatDate(request.date),
      request,
    ],
  });

export const usePrefetchResourceRequestsByWorkforceProviderIdAndDate = ({
  workforceProviderId,
  ...request
}: ResourceRequestsByWorkforceProviderIdAndDateProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'resource-request',
        'list',
        workforceProviderId,
        formatDate(request.date),
        ...Object.values({ workforceProviderId, ...request }),
      ],
      queryFn: async () =>
        ResourceRequestsByWorkforceProviderIdAndDate({
          workforceProviderId,
          ...request,
        }),
    });
  }, [workforceProviderId, queryClient, request]);
};

export const ResourceRequestsByWorkforceProviderIdAndDateInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isResourceRequest(data)) {
      queryClient.invalidateQueries({
        queryKey: [
          'resource-request',
          'list',
          data.workforceProviderId,
          formatDate(data.startTime),
        ],
      });
    }
  };
