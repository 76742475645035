import { isResourceRequest, ResourceRequest } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ResourceRequestsByTenantIdAndDateProps = Filterable &
  Pagination & {
    date: Date;
  };

type Response = AxiosResponse<Collection<ResourceRequest>>;

export const ResourceRequestsByTenantIdAndDate = async ({
  date,
  ...params
}: ResourceRequestsByTenantIdAndDateProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/resource-requests-per-date/${formatDate(date)}`,
    {
      params: {
        ...params,
      },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    }
  );

export const useResourceRequestsByTenantIdAndDate = (
  { ...request }: ResourceRequestsByTenantIdAndDateProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      ResourceRequestsByTenantIdAndDate({
        ...request,
      }),
    queryKey: ['resource-request', 'list', formatDate(request.date), request],
  });

export const ResourceRequestsByTenantIdAndDateInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isResourceRequest(data)) {
    queryClient.invalidateQueries({
      queryKey: ['resource-request', 'list', formatDate(data.startTime)],
    });
  }
};
