import { isObjectResponse } from '@bas/value-objects';
import type { WorkforceProvider } from '../types';

export function isWorkforceProvider(
  object: unknown
): object is WorkforceProvider {
  return (
    !!object &&
    isObjectResponse(object) &&
    ['WorkforceProvider', 'TempAgency', 'Coopetitor'].includes(object['@type'])
  );
}
