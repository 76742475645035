import { isObjectResponse } from '@bas/value-objects';
import type { ResourceRequest } from '../types';

export function isResourceRequest(object: unknown): object is ResourceRequest {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'ResourceRequest'
  );
}
