import { ResourceRequestStatus } from '@bas/hrm-domain/models';
import { Chip, ChipProps } from '@bas/ui/web/atoms';
import { Badge, Box } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type ResourceRequestStatusChipProps = Partial<ChipProps> & {
  resourceRequestStatus: ResourceRequestStatus;
};

const statusColors: {
  [key: string]:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
} = {
  [ResourceRequestStatus.PENDING]: 'warning',
  [ResourceRequestStatus.INVITED]: 'info',
  [ResourceRequestStatus.ACCEPTED]: 'success',
  [ResourceRequestStatus.DECLINED]: 'error',
  [ResourceRequestStatus.CANCELLED]: 'warning',
};

const ResourceRequestStatusChip = ({
  resourceRequestStatus,
  ...props
}: ResourceRequestStatusChipProps): ReactElement => (
  <Chip
    variant="outlined"
    label={
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {statusColors[resourceRequestStatus] && (
          <Badge
            color={statusColors[resourceRequestStatus]}
            badgeContent=" "
            variant="dot"
          />
        )}
        <span style={{ paddingLeft: '8px' }}>
          <FormattedMessage
            id={`resourceRequestStatus.${resourceRequestStatus}`}
          />
        </span>
      </Box>
    }
    {...props}
  />
);

export default ResourceRequestStatusChip;
