import {
  isResourceRequest,
  TenantResourceRequestsPerDay,
} from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ResourceRequestsByDateProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<TenantResourceRequestsPerDay>>;

export const ResourceRequestsByDate = async ({
  ...params
}: ResourceRequestsByDateProps): Promise<Response> =>
  axios.get(`api/{tenantId}/hrm/resource-requests-per-date`, {
    params: {
      ...params,
    },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useResourceRequestsByDate = (
  { ...request }: ResourceRequestsByDateProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      ResourceRequestsByDate({
        ...request,
      }),
    queryKey: ['resource-request', 'list', request],
  });

export const ResourceRequestsByDateInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isResourceRequest(data)) {
    queryClient.invalidateQueries({
      queryKey: ['resource-request', 'list'],
    });
  }
};
