import { MovingJobSkill, OfficeSkill } from '@bas/hrm-domain/models';
import { EventType, Uuid } from '@bas/value-objects';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { zustandStorage } from './zustandStorage';

type CreateEvent = {
  start?: Date | null;
  end?: Date | null;
  fullDay?: boolean;
  resourceId?: Uuid;
  resourceType?: 'employee' | 'vehicle';
};

type PlanningSettings = {
  disableLateTimeline: boolean;
  showOnlyEmployeesWithOneOfTheFollowingSkills: (
    | OfficeSkill
    | MovingJobSkill
  )[];
};

export type PlanningFilterInputType = {
  eventTypes: EventType[];
  employee?: Uuid;
  temporaryPlannableResource?: Uuid;
  identityId?: Uuid;
};

type PlanningStoreType = {
  widthPerEvent: number;
  halfHourHeight: number;
  currentDate: Date;
  isFilterOpen: boolean;
  isSettingsOpen: boolean;
  isShowingAvailability: boolean;
  isAddingTemporaryWorker: boolean;

  createEvent?: CreateEvent;
  filters: PlanningFilterInputType;
  settings: PlanningSettings;

  setSettings: (settings: PlanningSettings) => void;
  setFilters: (filters: PlanningFilterInputType) => void;
  setWidthPerEvent: (widthPerEvent: number) => void;
  setHalfHourHeight: (halfHourHeight: number) => void;
  setCurrentDate: (currentDate: Date) => void;
  toggleFilter: () => void;
  toggleSettings: () => void;
  toggleShowingAvailability: () => void;
  openAddingTemporaryWorker: () => void;
  closeAddingTemporaryWorker: () => void;
  setCreateEvent: (createEvent: CreateEvent | undefined) => void;
};
export const usePlanningStore = create<PlanningStoreType>()(
  persist(
    (set) => ({
      widthPerEvent: 198.5,
      halfHourHeight: 61,
      currentDate: new Date(),
      isFilterOpen: false,
      isSettingsOpen: false,
      isShowingAvailability: true,
      isAddingTemporaryWorker: false,
      createEvent: undefined,
      filters: {
        eventTypes: Object.values(EventType).filter((type) => !!type),
        employee: '',
        temporaryPlannableResource: '',
        identityId: '',
      },
      settings: {
        disableLateTimeline: false,
        showOnlyEmployeesWithOneOfTheFollowingSkills: [],
      },
      setSettings: (settings: PlanningSettings) => set({ settings }),
      setWidthPerEvent: (widthPerEvent: number) => set({ widthPerEvent }),
      setHalfHourHeight: (halfHourHeight: number) => set({ halfHourHeight }),
      setCurrentDate: (currentDate: Date) => set({ currentDate }),
      toggleFilter: () =>
        set((state) => ({ isFilterOpen: !state.isFilterOpen })),
      toggleSettings: () =>
        set((state) => ({ isSettingsOpen: !state.isSettingsOpen })),
      toggleShowingAvailability: () =>
        set((state) => ({
          isShowingAvailability: !state.isShowingAvailability,
        })),
      openAddingTemporaryWorker: () =>
        set({
          isAddingTemporaryWorker: true,
        }),
      closeAddingTemporaryWorker: () =>
        set({
          isAddingTemporaryWorker: false,
        }),
      setCreateEvent: (createEvent: CreateEvent | undefined) =>
        set({ createEvent }),
      setFilters: (filters: PlanningFilterInputType) => set({ filters }),
    }),
    {
      version: 1,
      name: 'planning',
      storage: zustandStorage<PlanningStoreType>(), // eslint-disable-next-line @typescript-eslint/no-explicit-any
      migrate: (state: any, version) => {
        if (!state || version >= 1) {
          return state;
        }

        if (
          !version ||
          (version < 1 &&
            Object.prototype.hasOwnProperty.call(state, 'settings'))
        ) {
          return {
            ...state,
            settings: {
              ...state.settings,
              showOnlyEmployeesWithOneOfTheFollowingSkills: [],
            },
          };
        }

        return state;
      },
    }
  )
);
