import { fontSizesWeb } from '@bas/theme';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';
import { Badge } from '../Badge';
import {
  SidebarListItem,
  SidebarListItemProps,
  SidebarSizeType,
} from '../SidebarListItem';

export type MenuListItemProps = SidebarListItemProps & {
  icon: IconProp;
  label: ReactElement | string;
  badge?: ReactElement | string;
};

const MenuListItem = ({ icon, label, badge, ...args }: MenuListItemProps) => {
  let content = (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item className="MenuListItem-Icon">
        <FontAwesomeIcon icon={icon} />
      </Grid>
      <Grid item className="MenuListItem-Label">
        {label}
      </Grid>
    </Grid>
  );

  if (badge) {
    content = (
      <Badge badgeContent={badge} color="primary">
        {content}
      </Badge>
    );
  }

  return <SidebarListItem {...args}>{content}</SidebarListItem>;
};

MenuListItem.defaultProps = {
  active: false,
  size: SidebarSizeType.MEDIUM,
};

const StyledMenuListItem = styled(MenuListItem)(
  ({ theme }) => `
  position: relative;
  .MenuListItem-Icon {
    font-size: 1.65rem;
    margin-bottom: 4px;
  }

  .MenuListItem-Label {
    font-size: ${fontSizesWeb.xs};
    font-family: ${theme.typography.fontFamily};
  }
`
);
export default StyledMenuListItem;
