import { isResourceRequest, ResourceRequest } from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { ErrorResponse, Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type ResourceRequestByResourceRequestIdProps = {
  resourceRequestId: Uuid;
};

type Response = AxiosResponse<ResourceRequest>;

export const ResourceRequestByResourceRequestId = async ({
  resourceRequestId,
  ...params
}: ResourceRequestByResourceRequestIdProps): Promise<Response> =>
  axios.get(`api/{tenantId}/hrm/resource-requests/${resourceRequestId}`, {
    params: {
      ...params,
    },
    paramsSerializer(param) {
      return Qs.stringify(param, { arrayFormat: 'brackets' });
    },
  });

export const useResourceRequestByResourceRequestId = (
  { ...request }: ResourceRequestByResourceRequestIdProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      ResourceRequestByResourceRequestId({
        ...request,
      }),
    queryKey: ['resource-request', 'details', request.resourceRequestId],
  });

export const ResourceRequestByResourceRequestIdInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isResourceRequest(data)) {
    queryClient.invalidateQueries({
      queryKey: ['resource-request', 'details', data.resourceRequestId],
    });
  }
};
