import { isResourceRequest } from '@bas/hrm-domain/models';
import {
  isEvent,
  TemporaryEmployeeAvailabilityByDate,
} from '@bas/planning-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { EmployeeProjectRole, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TemporaryEmployeesAvailabilityByRoleAndDateRequestProps = {
  projectRole: EmployeeProjectRole;
  date: Date;
};

type Response = AxiosResponse<TemporaryEmployeeAvailabilityByDate>;
type QueryKeyType = QueryKey & {
  [0]: 'temporary-employee-availability';
  [1]: 'list';
  [2]: string;
  [3]: string;
};

export const TemporaryEmployeesAvailabilityByRoleAndDateRequest = async ({
  projectRole,
  date,
  ...params
}: TemporaryEmployeesAvailabilityByRoleAndDateRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/planning/availability/temporary-employee/${projectRole}/${formatDate(
      date
    )}`,
    {
      params: { ...params },
    }
  );

export const useTemporaryEmployeesAvailabilityByRoleAndDateRequest = (
  request: TemporaryEmployeesAvailabilityByRoleAndDateRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () =>
      TemporaryEmployeesAvailabilityByRoleAndDateRequest({ ...request }),
    queryKey: [
      'temporary-employee-availability',
      'list',
      formatDate(request.date),
      request.projectRole,
    ],
  });

export const TemporaryEmployeesAvailabilityByRoleAndDateRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isEvent(data)) {
      queryClient.invalidateQueries({
        queryKey: [
          'temporary-employee-availability',
          'list',
          formatDate(data.start),
        ],
      });
    }
    if (isResourceRequest(data)) {
      queryClient.invalidateQueries({
        queryKey: [
          'temporary-employee-availability',
          'list',
          formatDate(data.startTime),
        ],
      });
    }
  };
