import { isObjectResponse } from '@bas/value-objects';
import type { PlannedResourceRequest } from '../types';

export function isPlannedResourceRequest(
  object: unknown
): object is PlannedResourceRequest {
  return (
    !!object &&
    typeof object === 'object' &&
    isObjectResponse(object) &&
    object['@type'] === 'PlannedResourceRequest'
  );
}
