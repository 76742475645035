import { ResourceRequest } from '@bas/hrm-domain/models';
import {
  useTemporaryEmployeeByTemporaryPlannableResourceIdRequestQuery,
  useWorkforceProviderByWorkforceProviderIdRequestQuery,
} from '@bas/hrm-domain/requests';
import { useTenantStore } from '@bas/shared/state';
import { basPaperclip } from '@bas/svg-icons';
import { colors } from '@bas/theme';
import { AddressBlock, Table, TableCell, TableRow } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { WorkflowButtons, WorkflowButtonsProps } from '@bas/ui/web/molecules';
import {
  Box,
  BoxProps,
  Skeleton,
  styled,
  TableBody,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useMemo } from 'react';
import {
  FormattedDate,
  FormattedDateTimeRange,
  FormattedMessage,
} from 'react-intl';
import ResourceRequestStatusChip from '../ResourceRequestStatusChip/ResourceRequestStatusChip';

type ResourceRequestItemProps = BoxProps & {
  resourceRequest: ResourceRequest;
  showWorkforceProvider?: boolean;
  workflowProps: WorkflowButtonsProps;
};

const ResourceRequestItem = ({
  resourceRequest,
  showWorkforceProvider,
  workflowProps,
  ...props
}: ResourceRequestItemProps): ReactElement => {
  const startTime = useMemo(
    () => dayjs(resourceRequest.startTime).toDate(),
    [resourceRequest.startTime]
  );

  const endTime = useMemo(
    () => dayjs(resourceRequest.endTime).toDate(),
    [resourceRequest.endTime]
  );

  const tenantCountry = useTenantStore(
    (state) => state.tenant?.address?.country || null
  );

  const { data: temporaryEmployeeData, isLoading: isLoadingTemporaryEmployee } =
    useTemporaryEmployeeByTemporaryPlannableResourceIdRequestQuery(
      {
        temporaryPlannableResourceId:
          resourceRequest.temporaryPlannableResourceId || '',
      },
      {
        enabled: !!resourceRequest.temporaryPlannableResourceId,
      }
    );

  const { data: workforceProviderData, isLoading: isLoadingWorkforceProvider } =
    useWorkforceProviderByWorkforceProviderIdRequestQuery(
      {
        workforceProviderId: resourceRequest.workforceProviderId || '',
      },
      {
        enabled: !!resourceRequest.workforceProviderId && showWorkforceProvider,
      }
    );

  return (
    <Box {...props}>
      <Box className="Bas-ResourceRequestItem-PaperClip">
        <Icon icon={basPaperclip} />
      </Box>
      <Box className="Bas-ResourceRequestItem-Header">
        <Typography color="black" fontWeight={700}>
          {resourceRequest.resourceRequestCode}
          {' - '}
          <FormattedDateTimeRange
            from={startTime}
            to={endTime}
            dateStyle="medium"
            timeStyle="short"
          />
        </Typography>
      </Box>
      <Box className="Bas-ResourceRequestItem-Content">
        <Box pb={2}>
          <WorkflowButtons {...workflowProps} />
        </Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>
                  <FormattedMessage id="label.status" />
                </Typography>
              </TableCell>
              <TableCell>
                <ResourceRequestStatusChip
                  resourceRequestStatus={resourceRequest.status}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  <FormattedMessage id="label.employee" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {resourceRequest.temporaryPlannableResourceId ? (
                    <span>
                      {isLoadingTemporaryEmployee ? (
                        <Skeleton width={100} />
                      ) : (
                        temporaryEmployeeData?.data?.name
                      )}
                    </span>
                  ) : (
                    <FormattedMessage id="label.noEmployeeSelected" />
                  )}
                </Typography>
              </TableCell>
            </TableRow>
            {resourceRequest.workforceProviderId && showWorkforceProvider && (
              <TableRow>
                <TableCell>
                  <Typography>
                    <FormattedMessage id="label.workforceProvider" />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {isLoadingWorkforceProvider ? (
                      <Skeleton width={100} />
                    ) : (
                      workforceProviderData?.data?.companyInformation
                        ?.companyName
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <Typography>
                  <FormattedMessage id="label.invitedAt" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {resourceRequest.invitedAt ? (
                    <FormattedDate
                      value={resourceRequest.invitedAt}
                      dateStyle="medium"
                      timeStyle="short"
                    />
                  ) : (
                    <FormattedMessage id="label.notInvited" />
                  )}
                </Typography>
              </TableCell>
            </TableRow>

            {resourceRequest.acceptedAt && (
              <TableRow>
                <TableCell>
                  <Typography>
                    <FormattedMessage id="label.acceptedAt" />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    <FormattedDate
                      value={resourceRequest.acceptedAt}
                      dateStyle="medium"
                      timeStyle="short"
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {resourceRequest.declinedAt && (
              <TableRow>
                <TableCell>
                  <Typography>
                    <FormattedMessage id="label.declinedAt" />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    <FormattedDate
                      value={resourceRequest.declinedAt}
                      dateStyle="medium"
                      timeStyle="short"
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            )}

            {resourceRequest.cancelledAt && (
              <TableRow>
                <TableCell>
                  <Typography>
                    <FormattedMessage id="label.cancelledAt" />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    <FormattedDate
                      value={resourceRequest.cancelledAt}
                      dateStyle="medium"
                      timeStyle="short"
                    />
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={2}>
                <Typography paddingTop={2}>
                  <AddressBlock
                    address={resourceRequest.startLocation}
                    showLink
                    showCountry={
                      resourceRequest.startLocation.country !== tenantCountry
                    }
                    addressLabel={<FormattedMessage id="label.startLocation" />}
                  />
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

const StyledResourceRequestItem = styled(ResourceRequestItem)`
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  min-height: 200px;
  height: 100%;
  border-radius: 3px;
  background: ${colors.lila[200]};
  position: relative;

  .Bas-ResourceRequestItem-PaperClip {
    position: absolute;
    right: 18px;
    top: -12px;
    font-size: 28px;
    color: ${colors.lila[600]};
  }

  .Bas-ResourceRequestItem-Header {
    padding-left: 5px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${colors.lila[400]};

    span {
      text-transform: lowercase;
    }
  }

  .Bas-ResourceRequestItem-Content {
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};

    .MuiTableRow-root {
      & > .MuiTableCell-root {
        border-bottom: none;

        &:first-of-type {
          padding-right: 20px;
        }
      }
    }
  }
`;

export default StyledResourceRequestItem;
