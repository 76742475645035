import {
  useAuthStore,
  useEmployeeStore,
  useIntercomStore,
  usePublicAuthStore,
  useUserStore,
} from '@bas/shared/state';
import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';

export const useLogout = (
  { callback }: { callback?: () => void } = {
    callback: undefined,
  }
) => {
  const logoutAuth = useAuthStore((state) => state.logout);
  const logoutPublicAuth = usePublicAuthStore((state) => state.logout);
  const logoutIntercom = useIntercomStore((state) => state.logout);
  const logoutUser = useUserStore((state) => state.logout);
  const logoutEmployee = useEmployeeStore((state) => state.logout);

  const { shutdown } = useIntercom();

  return useCallback(() => {
    logoutAuth();
    logoutPublicAuth();
    logoutIntercom();
    logoutUser();
    logoutEmployee();
    shutdown();
    callback?.();
  }, [
    callback,
    logoutAuth,
    logoutPublicAuth,
    logoutEmployee,
    logoutIntercom,
    logoutUser,
    shutdown,
  ]);
};
