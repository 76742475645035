import { colors } from '@bas/theme';
import { Avatar, AvatarSizeType } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { ContentSection } from '@bas/ui/web/molecules';
import { EmployeeProjectRole, PersonName } from '@bas/value-objects';
import { faPencil, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type PlannedEmployeeItemProps = {
  employee: {
    profilePictureUrl?: string;
    personName: PersonName;
  };
  projectRole: EmployeeProjectRole;
  extraInformation?: ReactNode;
  className?: string;
  onChange?: () => void | Promise<void>;
  onRemove?: () => void | Promise<void>;
};

const PlannedEmployeeItem = ({
  employee: {
    profilePictureUrl,
    personName: { firstName, lastName, fullName },
  },
  projectRole,
  className,
  onChange,
  onRemove,
  extraInformation,
}: PlannedEmployeeItemProps): ReactElement => {
  const actions = [];
  if (onChange) {
    actions.push(
      <IconButton onClick={onChange}>
        <Icon icon={faPencil} />
      </IconButton>
    );
  }
  if (onRemove) {
    actions.push(
      <IconButton onClick={onRemove}>
        <Icon icon={faTrashCan} />
      </IconButton>
    );
  }

  return (
    <ContentSection className={className} actions={actions}>
      <Box className="Bas-PlannedEmployeeItem-root">
        <Box className="Bas-PlannedEmployeeItem-Avatar">
          <Avatar
            size={AvatarSizeType.SMALL}
            src={profilePictureUrl}
            name={`${firstName} ${lastName}`}
            shortName={lastName}
          />
        </Box>
        <Grid container className="Bas-PlannedEmployeeItem-NameBlock">
          <Grid item xs={12} className="Bas-PlannedEmployeeItem-Role">
            <Typography>
              <FormattedMessage id={`projectRoles.${projectRole}`} />
            </Typography>
          </Grid>
          <Grid item xs={12} className="Bas-PlannedEmployeeItem-Name">
            <Typography>{firstName || fullName}</Typography>
            {extraInformation && (
              <Typography variant="caption">{extraInformation}</Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </ContentSection>
  );
};

const StyledPlannedEmployeeItem = styled(PlannedEmployeeItem)`
  .Bas-ContentSection-Actions {
    top: -${({ theme }) => theme.spacing(0)};
    right: ${({ theme }) => theme.spacing(0)};
  }

  &.Bas-ContentSection-HasActions {
    &:hover,
    &.Bas-ContentSection-Editing {
      &:before {
        left: -13px;
        right: -13px;
        top: -${({ theme }) => theme.spacing(1)};
        bottom: -${({ theme }) => theme.spacing(1)};
      }
    }
  }

  .Bas-PlannedEmployeeItem-root {
    display: flex;
    flex-direction: row;
    align-items: center;

    .Bas-PlannedEmployeeItem-Avatar {
      padding-right: 12px;
    }

    .Bas-PlannedEmployeeItem-Role .MuiTypography-root {
      color: ${colors.black};
    }

    .Bas-PlannedEmployeeItem-Name {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .MuiTypography-body1 {
        color: ${colors.black};
        font-weight: bold;
      }
    }
  }
`;

export default StyledPlannedEmployeeItem;
