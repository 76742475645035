import { isWorkforceProvider, WorkforceProvider } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type WorkforceProvidersRequestProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<WorkforceProvider>>;

export const WorkforceProvidersRequest = async ({
  ...params
}: WorkforceProvidersRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/hrm/workforce-providers', {
    params,
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });

export const useWorkforceProvidersRequest = (
  request: WorkforceProvidersRequestProps = {
    perPage: 9999,
    activeEmploymentOnly: true,
    backofficeOnly: false,
  },
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => WorkforceProvidersRequest(request),
    queryKey: ['workforce-providers', 'list', ...Object.values(request)],
  });

export const usePrefetchWorkforceProvidersRequest = (
  request: WorkforceProvidersRequestProps
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['workforce-providers', 'list', ...Object.values(request)],
      queryFn: async () => WorkforceProvidersRequest(request),
    });
  }, [queryClient, request]);
};

export const WorkforceProvidersRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isWorkforceProvider(data)) {
    queryClient.invalidateQueries({
      queryKey: ['workforce-providers', 'list'],
    });
  }
};
