import {
  DayOfInterestsByEmployeeIdRequestInvalidator,
  DeclarationByDeclarationIdRequestQueryInvalidator,
  DeclarationCategoriesRequestInvalidator,
  DeclarationCategoryByCategoryIdInvalidator,
  DeclarationsByEmployeeIdRequestInvalidator,
  DeclarationsRequestInvalidator,
  DeclarationStatisticsByEmployeeIdRequestInvalidator,
  DeclarationStatisticsRequestInvalidator,
  EmployeeByEmployeeIdRequestInvalidator,
  EmployeeOccasionalAvailabilitiesByEmployeeIdRequestInvalidator,
  EmployeesRequestInvalidator,
  HourEntriesByEmployeeIdRequestInvalidator,
  HourEntriesByEntryIdsRequestInvalidator,
  HourEntriesByEventIdRequestInvalidator,
  HourEntriesByProjectIdRequestInvalidator,
  HoursInsightByEmployeeIdRequestInvalidator,
  HoursOverviewRequestInvalidator,
  NationalHolidayByNationalHolidayIdRequestQueryInvalidator,
  NationalHolidaysRequestInvalidator,
  ResourceRequestsByDateInvalidator,
  ResourceRequestsByTemporaryPlannableResourceIdInvalidator,
  ResourceRequestsByTenantIdAndDateInvalidator,
  ResourceRequestsByWorkforceProviderIdAndDateInvalidator,
  ResourceRequestsByWorkforceProviderIdInvalidator,
  TeamByTeamIdRequestInvalidator,
  TeamsRequestInvalidator,
  TemporaryEmployeeByTemporaryPlannableResourceIdRequestInvalidator,
  TemporaryEmployeesRequestInvalidator,
  TimeEntriesByEmployeeIdRequestInvalidator,
  TimeEntriesRequestInvalidator,
  TimeEntryByTimeEntryIdRequestQueryInvalidator,
  TimeOffBalancesRequestInvalidator,
  TimeOffRegistrationByTimeOffIdRequestQueryInvalidator,
  TimeOffRegistrationsByEmployeeIdRequestInvalidator,
  TimeOffRegistrationsRequestInvalidator,
  TimeOffStatisticsByEmployeeIdRequestInvalidator,
  TimeOffTypeByTimeOffTypeIdInvalidator,
  TimeOffTypesRequestInvalidator,
  TimeTypeByTimeTypeIdInvalidator,
  TimeTypeCategoriesRequestInvalidator,
  TimeTypesRequestInvalidator,
  WorkforceProviderByWorkforceProviderIdRequestInvalidator,
  WorkforceProvidersRequestInvalidator,
} from './lib';
import { ResourceRequestByResourceRequestIdInvalidator } from './lib/resourceRequestByResourceRequestId';
import { ResourceRequestsRequestInvalidator } from './lib/resourceRequestsRequest';

export const queryInvalidators = [
  DayOfInterestsByEmployeeIdRequestInvalidator,
  DeclarationByDeclarationIdRequestQueryInvalidator,
  DeclarationCategoriesRequestInvalidator,
  DeclarationCategoryByCategoryIdInvalidator,
  DeclarationsByEmployeeIdRequestInvalidator,
  DeclarationsRequestInvalidator,
  DeclarationStatisticsByEmployeeIdRequestInvalidator,
  DeclarationStatisticsRequestInvalidator,
  EmployeeByEmployeeIdRequestInvalidator,
  EmployeeOccasionalAvailabilitiesByEmployeeIdRequestInvalidator,
  EmployeesRequestInvalidator,
  HourEntriesByEmployeeIdRequestInvalidator,
  HourEntriesByEntryIdsRequestInvalidator,
  HourEntriesByEventIdRequestInvalidator,
  HourEntriesByProjectIdRequestInvalidator,
  HoursInsightByEmployeeIdRequestInvalidator,
  HoursOverviewRequestInvalidator,
  NationalHolidayByNationalHolidayIdRequestQueryInvalidator,
  NationalHolidaysRequestInvalidator,
  TeamByTeamIdRequestInvalidator,
  TeamsRequestInvalidator,
  TimeEntriesByEmployeeIdRequestInvalidator,
  TimeEntriesRequestInvalidator,
  TimeEntryByTimeEntryIdRequestQueryInvalidator,
  TimeOffBalancesRequestInvalidator,
  TimeOffRegistrationByTimeOffIdRequestQueryInvalidator,
  TimeOffRegistrationsByEmployeeIdRequestInvalidator,
  TimeOffRegistrationsRequestInvalidator,
  TimeOffStatisticsByEmployeeIdRequestInvalidator,
  TimeOffTypeByTimeOffTypeIdInvalidator,
  TimeOffTypesRequestInvalidator,
  TimeTypeByTimeTypeIdInvalidator,
  TimeTypeCategoriesRequestInvalidator,
  TimeTypesRequestInvalidator,
  TemporaryEmployeesRequestInvalidator,
  TemporaryEmployeeByTemporaryPlannableResourceIdRequestInvalidator,
  WorkforceProvidersRequestInvalidator,
  WorkforceProviderByWorkforceProviderIdRequestInvalidator,
  ResourceRequestsByTemporaryPlannableResourceIdInvalidator,
  ResourceRequestsByWorkforceProviderIdInvalidator,
  ResourceRequestsByWorkforceProviderIdAndDateInvalidator,
  ResourceRequestsByDateInvalidator,
  ResourceRequestsByTenantIdAndDateInvalidator,
  ResourceRequestsRequestInvalidator,
  ResourceRequestByResourceRequestIdInvalidator,
];
