import { isTemporaryEmployee, TemporaryEmployee } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type TemporaryEmployeesRequestProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<TemporaryEmployee>>;

export const TemporaryEmployeesRequest = async ({
  ...params
}: TemporaryEmployeesRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/hrm/temporary-employees', {
    params,
    paramsSerializer: {
      encode: (param) => Qs.parse(param),
      serialize: (param) => Qs.stringify(param, { arrayFormat: 'brackets' }),
    },
  });

export const useTemporaryEmployeesRequest = (
  request: TemporaryEmployeesRequestProps = {
    perPage: 9999,
  },
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () => TemporaryEmployeesRequest(request),
    queryKey: ['temporary-employees', 'list', ...Object.values(request)],
  });

export const usePrefetchTemporaryEmployeesRequest = (
  request: TemporaryEmployeesRequestProps
): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: ['temporary-employees', 'list', ...Object.values(request)],
      queryFn: async () => TemporaryEmployeesRequest(request),
    });
  }, [queryClient, request]);
};

export const TemporaryEmployeesRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isTemporaryEmployee(data)) {
    queryClient.invalidateQueries({
      queryKey: ['temporary-employees', 'list'],
    });
  }
};
