import { isResourceRequest, ResourceRequest } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type ResourceRequestsByTemporaryPlannableResourceIdProps = Pagination &
  Filterable & {
    temporaryPlannableResourceId: Uuid;
  };

type Response = AxiosResponse<Collection<ResourceRequest>>;

export const ResourceRequestsByTemporaryPlannableResourceId = async ({
  temporaryPlannableResourceId,
  ...params
}: ResourceRequestsByTemporaryPlannableResourceIdProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/temporary-employees/${temporaryPlannableResourceId}/resource-requests`,
    {
      params: {
        ...params,
      },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    }
  );

export const useResourceRequestsByTemporaryPlannableResourceId = (
  {
    temporaryPlannableResourceId,
    ...request
  }: ResourceRequestsByTemporaryPlannableResourceIdProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      ResourceRequestsByTemporaryPlannableResourceId({
        ...request,
        temporaryPlannableResourceId,
      }),
    queryKey: [
      'resource-request',
      'list',
      temporaryPlannableResourceId,
      request,
    ],
  });

export const usePrefetchResourceRequestsByTemporaryPlannableResourceId = ({
  temporaryPlannableResourceId,
  ...request
}: ResourceRequestsByTemporaryPlannableResourceIdProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'resource-request',
        'list',
        temporaryPlannableResourceId,
        ...Object.values({ temporaryPlannableResourceId, ...request }),
      ],
      queryFn: async () =>
        ResourceRequestsByTemporaryPlannableResourceId({
          temporaryPlannableResourceId,
          ...request,
        }),
    });
  }, [temporaryPlannableResourceId, queryClient, request]);
};

export const ResourceRequestsByTemporaryPlannableResourceIdInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isResourceRequest(data)) {
      queryClient.invalidateQueries({
        queryKey: [
          'resource-request',
          'list',
          data.temporaryPlannableResourceId,
        ],
      });
    }
  };
