import {
  isResourceRequest,
  MovingJobSkill,
  OfficeSkill,
} from '@bas/hrm-domain/models';
import {
  isEvent,
  TemporaryEmployeeAvailableHoursOverview,
} from '@bas/planning-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { formatDate } from '@bas/shared/utils';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';

export type TemporaryEmployeeAvailableHoursOverviewByPeriodRequestProps = {
  startDate: Date;
  endDate: Date;
  skills?: (OfficeSkill | MovingJobSkill)[];
};

type Response = AxiosResponse<
  Collection<TemporaryEmployeeAvailableHoursOverview>
>;
type QueryKeyType = QueryKey & {
  [0]: 'temporary-employee-available-hours-overview';
  [1]: 'list';
  [2]: string;
  [3]: string;
  [4]: string[] | undefined;
};

export const TemporaryEmployeeAvailableHoursOverviewByPeriodRequest = async ({
  startDate,
  endDate,
  ...params
}: TemporaryEmployeeAvailableHoursOverviewByPeriodRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/planning/by-temporary-employee/${formatDate(
      startDate
    )}/${formatDate(endDate)}`,
    {
      params: { ...params },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    }
  );

export const useTemporaryEmployeeAvailableHoursOverviewByPeriodRequest = (
  request: TemporaryEmployeeAvailableHoursOverviewByPeriodRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () =>
      TemporaryEmployeeAvailableHoursOverviewByPeriodRequest({ ...request }),
    queryKey: [
      'temporary-employee-available-hours-overview',
      'list',
      formatDate(request.startDate),
      formatDate(request.endDate),
      request.skills,
    ],
  });

export const TemporaryEmployeeAvailableHoursOverviewByPeriodRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isEvent(data) || isResourceRequest(data)) {
      queryClient.invalidateQueries({
        queryKey: ['temporary-employee-available-hours-overview', 'list'],
      });
    }
  };
