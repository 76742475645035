import { isTemporaryEmployee, TemporaryEmployee } from '@bas/hrm-domain/models';
import { QueryInvalidator, QueryOptionsWithKey } from '@bas/shared/requests';
import { Uuid } from '@bas/value-objects';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type TemporaryEmployeeByTemporaryPlannableResourceIdRequestProps = {
  temporaryPlannableResourceId: Uuid;
};

type Response = AxiosResponse<TemporaryEmployee>;

export const TemporaryEmployeeByTemporaryPlannableResourceIdRequest = async ({
  temporaryPlannableResourceId,
  ...params
}: TemporaryEmployeeByTemporaryPlannableResourceIdRequestProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/temporary-employees/${temporaryPlannableResourceId}`,
    {
      params,
    }
  );

export const useTemporaryEmployeeByTemporaryPlannableResourceIdRequestQuery = (
  request: TemporaryEmployeeByTemporaryPlannableResourceIdRequestProps,
  options: QueryOptionsWithKey<Response, AxiosError, Response> = {}
): UseQueryResult<Response, AxiosError> =>
  useQuery<Response, AxiosError, Response>({
    ...options,
    queryFn: async () =>
      TemporaryEmployeeByTemporaryPlannableResourceIdRequest(request),
    queryKey: [
      'temporary-employees',
      'detail',
      request.temporaryPlannableResourceId,
    ],
  });

export const TemporaryEmployeeByTemporaryPlannableResourceIdRequestInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isTemporaryEmployee(data)) {
      queryClient.invalidateQueries({
        queryKey: [
          'temporary-employees',
          'detail',
          data.temporaryPlannableResourceId,
        ],
      });
    }
  };
