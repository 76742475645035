import { useTenantStore } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { Logo, MenuListItemProps } from '@bas/ui/web/atoms';
import { Sidebar, SidebarProps } from '@bas/ui/web/organisms';
import { Box, BoxProps, Grid, LinearProgress, styled } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';
import { ReactElement, ReactNode, Suspense } from 'react';
import { ModuleLoadingPage } from '../ModuleLoadingPage';

type PortalTemplateProps = {
  mainMenuItems: MenuListItemProps[];
  bottomMenuItems: MenuListItemProps[];
  sidebarProps: Partial<
    Omit<SidebarProps, 'logo' | 'mainMenuList' | 'bottomMenuList'>
  >;
  toolbarChildren?: ReactNode;
} & BoxProps;

const PortalTemplate = ({
  mainMenuItems,
  bottomMenuItems,
  children,
  sidebarProps,
  toolbarChildren,
  ...props
}: PortalTemplateProps): ReactElement => {
  const isFetching = useIsFetching();
  const { tenantName } = useTenantStore((state) => ({
    tenantName: state.tenant?.name || state?.internalTenant?.name || '',
  }));

  return (
    <Box {...props}>
      {!!isFetching && (
        <Box className="Bas-PortalTemplate-Fetching">
          <LinearProgress variant="query" sx={{ height: '2px' }} />
        </Box>
      )}
      <Box className="Bas-PortalTemplate-Sidebar">
        <Sidebar
          logo={<Logo />}
          mainMenuList={{
            menuItems: mainMenuItems,
          }}
          bottomMenuList={{
            menuItems: bottomMenuItems,
          }}
          {...sidebarProps}
        />
      </Box>
      <Box className="Bas-PortalTemplate-Content">
        <Box className="Bas-PortalTemplate-ToolBar">
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item>{tenantName}</Grid>
            {toolbarChildren}
          </Grid>
        </Box>
        <Box className="Bas-PortalTemplate-Content-Body">
          <Suspense fallback={<ModuleLoadingPage />}>{children}</Suspense>
        </Box>
      </Box>
    </Box>
  );
};

const StyledPortalTemplate = styled(PortalTemplate)`
  display: flex;
  overflow: hidden;
  height: 100%;

  .Bas-PortalTemplate-Fetching {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
  }

  .Bas-PortalTemplate-Content {
    position: relative;
    height: 100vh;
    width: calc(100% - 88px);
  }

  .Bas-PortalTemplate-ToolBar {
    top: 0;
    left: 0;
    right: 0;
    box-sizing: content-box;
    background: #fff;
    border-bottom: 1px solid ${colors.lila[300]};
    height: 56px;
    padding: 0 ${({ theme }) => theme.spacing(6)};
    display: flex;
    align-items: center;
  }

  .Bas-PortalTemplate-Content-Body {
    height: calc(100% - 56px);
  }
`;

export default StyledPortalTemplate;
