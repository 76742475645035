import {
  AvailableHoursByRoleAndPeriodRequestInvalidator,
  CanPayAtCustomerByEventIdRequestInvalidator,
  DayWithHoursToBeApprovedOrWithoutHoursRequestInvalidator,
  EmployeeAvailableHoursOverviewByPeriodRequestInvalidator,
  EmployeesAvailabilityByRoleAndDateRequestInvalidator,
  EventByEventIdRequestInvalidator,
  EventByPeriodRequestInvalidator,
  EventsByEmployeeIdRequestInvalidator,
  EventsByEventIdsRequestInvalidator,
  EventsByMaterialIdRequestInvalidator,
  EventsByProjectIdRequestInvalidator,
  MaterialAvailableHoursOverviewByPeriodRequestInvalidator,
  MaterialsAvailabilityByDateRequestInvalidator,
  MaterialsByMaterialIdsRequestInvalidator,
  MaterialsRequestInvalidator,
  MileageReportsByMaterialIdRequestInvalidator,
  MileageReportsByProjectIdRequestInvalidator,
  RecurringEventsByEmployeeIdRequestInvalidator,
  RecurringEventsByPeriodRequestInvalidator,
  TemporaryEmployeesAvailabilityByRoleAndDateRequestInvalidator,
  TenantClosureDatesRequestInvalidator,
  TravelTimeForEventByEventIdRequestInvalidator,
  TravelTimeForEventsByDateRequestInvalidator,
  TravelTimeToEventByEventIdRequestInvalidator,
  VehicleByMaterialIdRequestInvalidator,
  VehiclesByMaterialIdsRequestInvalidator,
  VehiclesRequestInvalidator,
} from './lib';
import { TemporaryEmployeeAvailableHoursOverviewByPeriodRequestInvalidator } from './lib/temporaryEmployeeAvailableHoursOverviewByPeriodRequest';

export const queryInvalidators = [
  AvailableHoursByRoleAndPeriodRequestInvalidator,
  CanPayAtCustomerByEventIdRequestInvalidator,
  DayWithHoursToBeApprovedOrWithoutHoursRequestInvalidator,
  EmployeeAvailableHoursOverviewByPeriodRequestInvalidator,
  EmployeesAvailabilityByRoleAndDateRequestInvalidator,
  EventByEventIdRequestInvalidator,
  EventsByEmployeeIdRequestInvalidator,
  EventsByEventIdsRequestInvalidator,
  EventsByMaterialIdRequestInvalidator,
  EventByPeriodRequestInvalidator,
  EventsByProjectIdRequestInvalidator,
  MaterialAvailableHoursOverviewByPeriodRequestInvalidator,
  MaterialsAvailabilityByDateRequestInvalidator,
  MaterialsByMaterialIdsRequestInvalidator,
  MaterialsRequestInvalidator,
  MileageReportsByMaterialIdRequestInvalidator,
  MileageReportsByProjectIdRequestInvalidator,
  TenantClosureDatesRequestInvalidator,
  TravelTimeForEventByEventIdRequestInvalidator,
  TravelTimeForEventsByDateRequestInvalidator,
  TravelTimeToEventByEventIdRequestInvalidator,
  VehicleByMaterialIdRequestInvalidator,
  VehiclesByMaterialIdsRequestInvalidator,
  VehiclesRequestInvalidator,
  RecurringEventsByPeriodRequestInvalidator,
  RecurringEventsByEmployeeIdRequestInvalidator,
  TemporaryEmployeeAvailableHoursOverviewByPeriodRequestInvalidator,
  TemporaryEmployeesAvailabilityByRoleAndDateRequestInvalidator,
];
