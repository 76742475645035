import { isResourceRequest, ResourceRequest } from '@bas/hrm-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Qs from 'qs';
import { useEffect } from 'react';

export type ResourceRequestsByWorkforceProviderIdProps = Pagination &
  Filterable & {
    workforceProviderId: Uuid;
  };

type Response = AxiosResponse<Collection<ResourceRequest>>;

export const ResourceRequestsByWorkforceProviderId = async ({
  workforceProviderId,
  ...params
}: ResourceRequestsByWorkforceProviderIdProps): Promise<Response> =>
  axios.get(
    `api/{tenantId}/hrm/workforce-providers/${workforceProviderId}/resource-requests`,
    {
      params: {
        ...params,
      },
      paramsSerializer(param) {
        return Qs.stringify(param, { arrayFormat: 'brackets' });
      },
    }
  );

export const useResourceRequestsByWorkforceProviderId = (
  {
    workforceProviderId,
    ...request
  }: ResourceRequestsByWorkforceProviderIdProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response>({
    ...options,
    queryFn: async () =>
      ResourceRequestsByWorkforceProviderId({
        ...request,
        workforceProviderId,
      }),
    queryKey: ['resource-request', 'list', workforceProviderId, request],
  });

export const usePrefetchResourceRequestsByWorkforceProviderId = ({
  workforceProviderId,
  ...request
}: ResourceRequestsByWorkforceProviderIdProps): void => {
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.prefetchQuery<Response, AxiosError<ErrorResponse>, Response>({
      queryKey: [
        'resource-request',
        'list',
        workforceProviderId,
        ...Object.values({ workforceProviderId, ...request }),
      ],
      queryFn: async () =>
        ResourceRequestsByWorkforceProviderId({
          workforceProviderId,
          ...request,
        }),
    });
  }, [workforceProviderId, queryClient, request]);
};

export const ResourceRequestsByWorkforceProviderIdInvalidator: QueryInvalidator =
  (data, queryClient) => {
    if (isResourceRequest(data)) {
      queryClient.invalidateQueries({
        queryKey: ['resource-request', 'list', data.workforceProviderId],
      });
    }
  };
