import { EventSourcePolyfill } from 'event-source-polyfill';
import NativeEventSource from 'react-native-sse';
import { isWeb } from './isWeb';

export const subscribeToMercure = (
  mercureToken: string,
  tenantId: string,
  userId: string,
  apiUrl: string,
  mercureUrl: string
): EventSource | NativeEventSource | EventSourcePolyfill => {
  if (!mercureUrl) {
    throw Error('No mercure url set');
  }

  const url = new URL(mercureUrl);

  url.searchParams.append(
    'topic',
    `https://bas-backend.wip/api/tenants/${tenantId}`
  );

  url.searchParams.append(
    'topic',
    `${apiUrl}/api/tenants/${tenantId}`.replace(
      `//api/tenants/${tenantId}`,
      `/api/tenants/${tenantId}`
    )
  );
  url.searchParams.append(
    'topic',
    `https://bas-backend.wip/api/${tenantId}/{+anything}`
  );

  url.searchParams.append(
    'topic',
    `${apiUrl}/api/${tenantId}/{+anything}`.replace(
      `//api/${tenantId}`,
      `/api/${tenantId}`
    )
  );

  if (userId) {
    url.searchParams.append(
      'topic',
      `https://bas-backend.wip/api/users/${userId}/{+anything}`
    );

    url.searchParams.append(
      'topic',
      `${apiUrl}/api/users/${userId}/{+anything}`.replace(`//api`, `/api`)
    );

    url.searchParams.append(
      'topic',
      `https://bas-backend.wip/api/users/${userId}`
    );

    url.searchParams.append(
      'topic',
      `${apiUrl}/api/users/${userId}`.replace(`//api`, `/api`)
    );
  }

  if (isWeb) {
    return new EventSourcePolyfill(url.toString(), {
      headers: {
        Authorization: `Bearer ${mercureToken}`,
      },
    });
  }

  return new NativeEventSource(url.toString(), {
    headers: {
      Authorization: `Bearer ${mercureToken}`,
    },
    debug: true,
  });
};
