import { queryInvalidators as authenticationQueryInvalidators } from '@bas/authentication-domain/requests';
import { queryInvalidators as communicationQueryInvalidators } from '@bas/communication-domain/requests';
import { queryInvalidators as crmQueryInvalidators } from '@bas/crm-domain/requests';
import { queryInvalidators as financialQueryInvalidators } from '@bas/financial-domain/requests';
import { queryInvalidators as hrmQueryInvalidators } from '@bas/hrm-domain/requests';
import { queryInvalidators as integrationQueryInvalidators } from '@bas/integration-domain/requests';
import { queryInvalidators as planningQueryInvalidators } from '@bas/planning-domain/requests';
import { queryInvalidators as projectQueryInvalidators } from '@bas/project-domain/requests';
import { useAuthStore, useTenantStore, useUserStore } from '@bas/shared/state';
import { subscribeToMercure } from '@bas/shared/utils';
import { queryInvalidators as taskQueryInvalidators } from '@bas/task-domain/requests';
import { queryInvalidators as tenantQueryInvalidators } from '@bas/tenant-domain/requests';
import { queryInvalidators as wmsQueryInvalidators } from '@bas/wms-domain/requests';
import { useQueryClient } from '@tanstack/react-query';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useSnackbar } from 'notistack';
import { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const allQueryInvalidators = [
  ...authenticationQueryInvalidators,
  ...communicationQueryInvalidators,
  ...crmQueryInvalidators,
  ...financialQueryInvalidators,
  ...hrmQueryInvalidators,
  ...integrationQueryInvalidators,
  ...planningQueryInvalidators,
  ...projectQueryInvalidators,
  ...taskQueryInvalidators,
  ...tenantQueryInvalidators,
  ...wmsQueryInvalidators,
];

type MercureSubscriberProps = {
  mercureToken: string | undefined;
};

const MercureSubscriber = ({ mercureToken }: MercureSubscriberProps): null => {
  const tenant = useTenantStore((state) => state.tenant);
  const authState = useAuthStore();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [setUserState] = useUserStore((state) => [state.setUser]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let eventSource: EventSource | any | EventSourcePolyfill = null;
    if (mercureToken && tenant?.tenantId) {
      eventSource = subscribeToMercure(
        mercureToken,
        tenant.tenantId,
        authState.userId || '',
        import.meta.env.VITE_API_URL || '',
        import.meta.env.VITE_MERCURE_URL || ''
      );

      if (!eventSource || !(eventSource instanceof EventSourcePolyfill)) {
        // eslint-disable-next-line no-console
        console.error('EventSource is null', eventSource);
        return () => {
          // eslint-disable-next-line no-console
          console.error('EventSource is null', eventSource);
        };
      }

      // eslint-disable-next-line no-console
      console.log('watching mercure events', import.meta.env.VITE_MERCURE_URL);
      eventSource.onmessage = (message) => {
        const data = JSON.parse(message.data);
        // eslint-disable-next-line no-console
        console.log('Got a new mercure message', message, data);

        if (data?.['@type'] === 'User') {
          if (
            authState.token &&
            authState.userId &&
            data.userId === authState.userId
          ) {
            setUserState(data);
          }
        }

        allQueryInvalidators.forEach((queryInvalidator) =>
          queryInvalidator(data, queryClient, tenant.tenantId)
        );
      };
    }

    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [
    mercureToken,
    tenant?.tenantId,
    queryClient,
    enqueueSnackbar,
    formatMessage,
    navigate,
    authState.userId,
    authState.token,
    setUserState,
  ]);

  return null;
};

const MemoizedMercureSubscriber = memo(MercureSubscriber);

export default MemoizedMercureSubscriber;
